export const searchLocation = (API, request, callback) => {
    API.get(`location`, { params: request }).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export const onAddLocation = (API, request, callback) => {
    API.post(`location`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onUpdateLocation = (API, request, callback) => {
    API.put(`location`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalUpdateLocationStatus = (API, request, callback) => {
    API.patch(`location/${request.id}/updateStatus/${request.isActive}`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}


export const getLocationType = (API, request, callback) => {
    API.get(`location/locationTypes`, { params: request }).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}