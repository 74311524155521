import { useState } from 'react';
import {
    Stack,
    Typography,
    Tooltip,
    Button,
    Icon
} from '@mui/material';
import { StyledIcon } from '../../components/nav-section/vertical/styles';
import SvgColor from '../../components/svg-color';

const icon = (name, color) => (
    <SvgColor src={`/assets/icons/setting/${name}.svg`} sx={{ width: 1, height: 1, color }} />
);

const ICONS = {
    correct: icon('ic_correct', 'green'),
    wrong: icon('ic_wrong', 'red'),
    edit: icon('ic_edit'),
    delete: icon('ic_delete'),
    activate: icon('ic_start'),
    deactivate: icon('ic_stop')
}

const renderStatusIcon = (status) => {
    if (status) {
        return <StyledIcon>{ICONS.correct}</StyledIcon>
    }
    return <StyledIcon>{ICONS.wrong}</StyledIcon>
}

const renderLocationTypeName = (params) => <Tooltip title={params.row.locationType ? params.row.locationType.name : ''}>
    <Stack direction="column">
        {params.row.locationType ? params.row.locationType.name : ''}
    </Stack>
</Tooltip>

/* eslint-disable react/prop-types */
const CustomizedButton = ({ text, styledIcon, onClick }) =>
    <Tooltip title={text}>
        <Button onClick={onClick}>
            <StyledIcon>{styledIcon}</StyledIcon>
        </Button>
    </Tooltip>

/* eslint-disable react/prop-types */
const RenderActionOptions = ({ Oncallback, row, btnicon,btntext }) =>
    <CustomizedButton text={btntext} onClick={(e) => {
        e.stopPropagation();
        Oncallback(row.id);
    }} styledIcon={btnicon} />

/* eslint-disable react/prop-types */
export const getLocationListColumns = (props) => [    
    {
        field: 'name', headerName: 'Name', width: 300, sortable: false
    },
    {
        field: 'description', headerName: 'Description', width: 300, sortable: false
    },
    {
        field: 'key', headerName: 'Key', width: 300, sortable: false
    },
    {
        field: 'latitude', headerName: 'Latitude', width: 300, sortable: false
    },
    {
        field: 'longitude', headerName: 'Longitude', width: 300, sortable: false
    },
    {
        field: 'locationTypeId', headerName: 'Location Type', width: 300, sortable: false,
        renderCell: (params) => renderLocationTypeName(params)
    },
    {
        field: 'photoURL', headerName: 'PhotoURL', width: 300, sortable: false
    },
    {
        field: 'websiteURL', headerName: 'WebsiteURL', width: 300, sortable: false
    },
    {
        field: 'phoneNumber', headerName: 'PhoneNumber', width: 300, sortable: false
    },
    {
        field: 'legacyId', headerName: 'LegacyId', width: 300, sortable: false
    },
    {
        field: 'placeId', headerName: 'PlaceId', width: 300, sortable: false
    },    
    {
        field: 'isApproved', headerName: 'IsApproved', width: 150, sortable: false,
        renderCell: (params) => renderStatusIcon(params.row.isApproved)
    },
    {
        field: 'isActive', headerName: 'IsActive', width: 150, sortable: false,
        renderCell: (params) => renderStatusIcon(params.row.isActive)
    },
    {
        field: 'action', headerName: 'Actions', width: 200, sortable: false,
        renderCell: (params) => (
            <>
                <RenderActionOptions Oncallback={props.onEdit} row={params.row} btnicon={ICONS.edit} btntext="Edit"/>
                {params.row.isActive ?
                    <RenderActionOptions Oncallback={props.onDeactive} row={params.row} btnicon={ICONS.deactivate} btntext="Deactive"/> :
                    <RenderActionOptions Oncallback={props.onActive} row={params.row} btnicon={ICONS.activate} btntext="Active"/>
                }
            </>
        )
    }
];