// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    login: path(ROOTS_AUTH, '/login'),
    forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_NOT_FOUND = '/404';

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    users: path(ROOTS_DASHBOARD, "/users"),
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    foods: path(ROOTS_DASHBOARD, '/foods'),
    labs: path(ROOTS_DASHBOARD, '/labs'),
    appusers: path(ROOTS_DASHBOARD, '/appusers'),
    diagnosis: path(ROOTS_DASHBOARD, '/diagnosis'),
    medication: path(ROOTS_DASHBOARD, '/medication'),
    symptoms: path(ROOTS_DASHBOARD, '/symptoms'),
    notifications: path(ROOTS_DASHBOARD, '/notifications'),
    comorbidities: path(ROOTS_DASHBOARD, '/comorbidities'),
    locations: path(ROOTS_DASHBOARD, '/locations'),
    section: path(ROOTS_DASHBOARD, '/section'),    
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        four: path(ROOTS_DASHBOARD, '/user/four'),
        five: path(ROOTS_DASHBOARD, '/user/five'),
        six: path(ROOTS_DASHBOARD, '/user/six'),
    },
};
