import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Grid, Paper, Typography, Alert } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from '../../components/settings';
import FormProvider, { RHFAutocomplete } from '../../components/hook-form';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { updateAppUserEmail, updateAppUserPassword } from '../../APIs/appUsers';
import { GridPaper, GridPaperSwitch } from '../../components/custom-grid-input';

const classes = {
    root: {
        flexGrow: 1
    },
    paper: {
        padding: 5,
        textAlign: "center",
        color: "inherit"
    }
};

/* eslint-disable react/prop-types */
export const AppUserView = ({ onChangeUser, selectedUser, updateEmail }) => {
    const { themeStretch } = useSettingsContext();
    const axiosPrivate = useAxiosPrivate();

    const EmailSchema = Yup.object().shape({
        id: Yup.number(),
        email: Yup.string().max(200).required('Email is required'),
    });

    const PasswordSchema = Yup.object().shape({
        id: Yup.number(),
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    const defaultValues = {
        id: selectedUser?.id ?? 0,
        email: selectedUser?.email ?? '',
        password: '',
        confirmPassword:''
    };

    const methods = useForm({
        resolver: updateEmail ? yupResolver(EmailSchema) : yupResolver(PasswordSchema),
        defaultValues
    });

    const {
        setValue,
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = methods;

    const handleSave = (request) => {
        const newRequest = { ...request }

        if (request.id > 0) {
            updateUser(newRequest);
        }
    }

    const updateUser = (request) => {
        if (updateEmail === true) {
            updateAppUserEmail(axiosPrivate, request, (response) => {
                if (response.status === 200) {
                    onChangeUser(response.data);
                }
                else {
                    console.log('error', response);

                    reset();

                    setError('afterSubmit', {
                        ...response,
                        message: response.data,
                    });
                }
            });
        }
        else {
            updateAppUserPassword(axiosPrivate, request, (response) => {
                if (response.status === 200) {
                    onChangeUser(response.data);
                }
                else {
                    console.log('error', response);

                    reset();

                    setError('afterSubmit', {
                        ...response,
                        message: response.data,
                    });
                }
            });
        }

    }

    return (
        <>
            <Helmet>
                <title>Update User {updateEmail ? 'Email' : 'Password'} | flok</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'} spacing={{ xs: 0.5, sm: 1.5 }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(handleSave)}>
                    <div style={classes.root}>
                        <Grid container selectedUser xs={12} spacing={1}>
                            {
                                updateEmail ?
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h4" component="h4" paragraph>
                                                Update Email
                                            </Typography>
                                            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                                        </Grid>
                                        <GridPaper name="email" label='Email' xs={12} readOnly={false} />
                                    </>
                                    :
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h4" component="h4" paragraph>
                                                Update Password
                                            </Typography>
                                            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                                        </Grid>
                                        <GridPaper name="password" label='Password' xs={12} readOnly={false} />
                                        <GridPaper name="confirmPassword" label='Confirm Password' xs={12} readOnly={false} />
                                    </>
                            }

                            <Grid container selectedUser xs={12} spacing={1}>
                                <Grid item xs={2}>
                                    <Paper style={classes.paper}>
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            loading={isSubmitSuccessful || isSubmitting}
                                        >
                                            {selectedUser ? "Update" : "Add"}
                                        </LoadingButton>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </FormProvider>
            </Container>
        </>
    )
}