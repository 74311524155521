import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// components
import LoadingScreen from '../components/loading-screen';
//
import { useAuthContext } from './useAuthContext';
import { Roles } from '../layouts/dashboard/nav/config-navigation';
// ----------------------------------------------------------------------

GuestGuard.propTypes = {
    children: PropTypes.node,
};

export default function GuestGuard({ children }) {
    const { isAuthenticated, isInitialized, user } = useAuthContext();

    if (isAuthenticated) {
        if (user?.role?.name === Roles.Admin) {
            return <Navigate to={PATH_DASHBOARD.dashboard} />;
        }

        return <Navigate to={PATH_DASHBOARD.section} />;
    }

    if (!isInitialized) {
        return <LoadingScreen />;
    }

    return <> {children} </>;
}
