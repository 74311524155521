// @mui
import { Pagination, TextField, Grid } from '@mui/material';

const rowsPerPage = [
    {
        value: 10,
        label: '10',
    },
    {
        value: 20,
        label: '20',
    },
    {
        value: 30,
        label: '30',
    },
    {
        value: 50,
        label: '50',
    },
    {
        value: 100,
        label: '100',
    }
];

/* eslint-disable react/prop-types */
const CustomPagination = ({ currentPage = 1, pageSize = 20, total = 0, handleChange, handlePageSizeChange }) => {
    const getCount = () => Math.ceil(total / pageSize);

    const onChange = (event, value) => {
        if (value !== currentPage)
            handleChange(value);
    };

    const onSizeChange = (e) => handlePageSizeChange(e.target.value);

    return (
        <Grid container spacing={1}>
            <Grid container item xs={12} spacing={1}>
                <Grid item xs={2}>
                    <TextField
                        size="small"
                        fullWidth
                        select
                        label="Rows per page"
                        defaultValue={pageSize}
                        SelectProps={{
                            native: true,
                        }}
                        onChange={onSizeChange}
                    >
                        {rowsPerPage.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <Pagination count={getCount()} page={currentPage} shape="rounded" onChange={onChange} />
                </Grid>
            </Grid>
        </Grid>
    )
};

export default CustomPagination;