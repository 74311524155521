export const getdiagnosis = (API, request, callback) => {
    API.get(`diagnosis`, { params: request }).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export const onAddDiagnosis = (API, request, callback) => {
    API.post(`diagnosis`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onUpdateDiagnosis = (API, request, callback) => {
    API.put(`diagnosis`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const getAlllDiagnosis = (API, request, callback) => {
    API.get(`diagnosis/getAll`).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}