import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { debounce } from "lodash";
// @mui
import { Container, Typography, Stack, Chip, Switch, FormControlLabel, TextField, Button, InputAdornment } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { getAppUserProfiles, deleteAppUserProfile,activeAppUserProfile } from '../../../APIs/appUsers';
// components
import { useSettingsContext } from '../../../components/settings';
import CustomPagination from '../../../components/custom-pagination';
import Iconify from '../../../components/iconify';
import CustomGrid from '../../../components/custom-grid';

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getUserProfileListColumn } from './appUserProfileColumns';

// ----------------------------------------------------------------------
import { useAuthContext } from '../../../auth/useAuthContext';

/* eslint-disable react/prop-types */
export const AppUserProfileList = ({ selectedUser }) => {
    const { themeStretch } = useSettingsContext();
    const axiosPrivate = useAxiosPrivate();
    const { user } = useAuthContext();

    const [totalProfile, setTotalProfile] = useState(0);
    const [Profiles, setProfiles] = useState([]);
    const [activeUsers, setactiveUsers] = useState(true);
    const [term, setTerm] = useState();
    const [fullWidth] = useState(true);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);

    const getUserProfile = (currentPage = 1) => {        
        const request = { userId : selectedUser.id, activeUsers, term, size, page: currentPage ?? page }
        setPage(request.page);

        getAppUserProfiles(axiosPrivate, request, (response) => {
            const { data, total } = response;
            setProfiles(data);
            setTotalProfile(total);            
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getUserProfile, [activeUsers, term, size]);

    const onDeactiveProfile = (id) => {        
        deleteAppUserProfile(axiosPrivate, { id }, (response) => {
            if (response.status === 200) {
                getUserProfile(1);
            }
        });
    }

    const onActiveProfile = (id) => {
        activeAppUserProfile(axiosPrivate, { id }, (response) => {
            if (response.status === 200) {
                getUserProfile(1);
            }
        });
    }

    const handleChange = (event) => {
        setactiveUsers(event.target.checked);        
    };

    const handleSearch = (event) => {
        setTerm(event.target.value);        
    }

    const handlePageChange = (pageIndex) => {
        setPage(pageIndex);
        getUserProfile(pageIndex);
    }

    const handlePageSizeChange = (pageSize) => {
        setSize(pageSize);
    }

    const debouncedChangeHandler = debounce(handleSearch, 500);

    const columns = getUserProfileListColumn({ onActiveProfile, onDeactiveProfile, currentUser: user });

    return (
        <>
            <Helmet>
                <title>User Profile | flok</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'}>
                <Stack
                    flexGrow={1}
                    direction="column"
                    justifyContent="flex-start"
                    spacing={{ xs: 0.5, sm: 1.5 }}>
                    <Typography variant="h4" component="h4" paragraph>
                        User Profile <Chip label={totalProfile} />
                    </Typography>
                    <Stack
                        flexGrow={1}
                        direction="row"
                        justifyContent="flex-start"
                        spacing={{ xs: 0.5, sm: 1.5 }}>
                        <TextField
                            hiddenLabel
                            placeholder="Search..."
                            size="small"
                            onChange={debouncedChangeHandler}
                            fullWidth={fullWidth}
                            defaultValue={term}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={activeUsers}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }} />
                            }
                            label="Active Profiles" />
                    </Stack>
                    <CustomGrid>                        
                                <DataGrid
                                    sx={{
                                        '.MuiDataGrid-columnHeaders': {
                                            backgroundColor: 'lightgray'
                                        },
                                        '.MuiDataGrid-columnSeparator': {
                                            display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                            border: 'none'
                                        },
                                        '.MuiDataGrid-footerContainer': {
                                            display: 'none'
                                        }
                                    }}
                                    rows={Profiles}
                                    columns={columns}
                                    disableColumnMenu
                                    disableColumnFilter
                                    disableRowSelectionOnClick
                                    hideFooterPagination
                                />                                                        
                    </CustomGrid>
                    <CustomPagination pageSize={size} currentPage={page} total={totalProfile} handleChange={handlePageChange} handlePageSizeChange={handlePageSizeChange} />
                </Stack>
            </Container>
        </>
    );
}