import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Grid, Paper, Typography, Alert } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from '../../components/settings';
import FormProvider, { RHFAutocomplete } from '../../components/hook-form';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { onAddLocation, onUpdateLocation, getLocationType } from '../../APIs/locations';
import { GridPaper, GridPaperSelect, GridPaperSwitch } from '../../components/custom-grid-input';

const classes = {
    root: {
        flexGrow: 1
    },
    paper: {
        padding: 5,
        textAlign: "center",
        color: "inherit"
    }
};

/* eslint-disable react/prop-types */
export const LocationView = ({ onChange, item, onHandleClose, readOnly = true }) => {
    const { themeStretch } = useSettingsContext();
    const axiosPrivate = useAxiosPrivate();
    const [locationType, setLocationType] = useState([]);

    const getDataSources = () => {
        getLocationType(axiosPrivate, {}, (response) => {
            if (response.status === 200) {
                setLocationType(response.data);
            }
            else {
                setLocationType([]);
            }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getDataSources, []);


    const AddLocationSchema = Yup.object().shape({
        id: Yup.number(),
        name: Yup.string().max(100).required('Name is required'),
        description: Yup.string(),
        key: Yup.string().max(100).required('Key is required'),
        latitude: Yup.number(),
        longitude: Yup.number(),
        photoURL: Yup.string(),
        websiteURL: Yup.string(),
        phoneNumber: Yup.string(),
        legacyId: Yup.string(),
        placeId: Yup.string(),
        isApproved: Yup.boolean(),
        isActive: Yup.boolean(),
        locationTypeId: Yup.number().required('Location Type is required'),
    });

    const defaultValues = {
        id: item?.id ?? 0,
        name: item?.name ?? '',
        description: item?.description ?? '',
        key: item?.key ?? '',
        latitude: item?.latitude ?? null,
        longitude: item?.longitude ?? null,
        photoURL: item?.photoURL ?? '',
        websiteURL: item?.websiteURL ?? '',
        phoneNumber: item?.phoneNumber ?? '',
        legacyId: item?.legacyId ?? '',
        placeId: item?.placeId ?? '',
        isApproved: item?.isApproved ?? false,
        isActive: item?.isActive ?? true,
        locationTypeId: item?.locationTypeId ?? '',
    };

    const methods = useForm({
        resolver: yupResolver(AddLocationSchema),
        defaultValues
    });

    const {
        setValue,
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = methods;

    const handleSave = (request) => {
        const newRequest = { ...request }

        if (request.id > 0) {
            updateLocation(newRequest);
        }
        else {
            addLocation(newRequest);
        }
    }

    const addLocation = (request) => {
        onAddLocation(axiosPrivate, request, (response) => {
            if (response.status === 201) {
                onChange(response.data);
            }
            else {
                console.log('error', response);

                reset();

                setError('afterSubmit', {
                    ...response,
                    message: response.data,
                });
            }
        });
    }

    const updateLocation = (request) => {
        onUpdateLocation(axiosPrivate, request, (response) => {
            if (response.status === 200) {
                onChange(response.data);
            }
            else {
                console.log('error', response);

                reset();

                setError('afterSubmit', {
                    ...response,
                    message: response.data,
                });
            }
        });
    }

    return (
        <>
            <Helmet>
                <title>View Location | flok</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'} spacing={{ xs: 0.5, sm: 1.5 }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(handleSave)}>
                    <div style={classes.root}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4" paragraph>
                                    {readOnly ? 'View Location' : `${item ? 'Update' : 'Add'} Location`}
                                </Typography>
                                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                            </Grid>
                            <GridPaper name="name" label='Name' xs={12} readOnly={readOnly} />
                            <GridPaper name="description" label='Description' xs={12} readOnly={readOnly} />
                            <GridPaper name="key" label='Key' xs={12} readOnly={readOnly} />
                            <GridPaper name="latitude" label='Latitude' xs={12} readOnly={readOnly} type="number"/>
                            <GridPaper name="longitude" label='Longitude' xs={12} readOnly={readOnly} type="number"/>
                            <GridPaper name="photoURL" label='PhotoURL' xs={12} readOnly={readOnly} />
                            <GridPaper name="websiteURL" label='WebsiteURL' xs={12} readOnly={readOnly} />
                            <GridPaper name="phoneNumber" label='PhoneNumber' xs={12} readOnly={readOnly} />
                            <GridPaper name="legacyId" label='LegacyId' xs={12} readOnly={readOnly} />
                            <GridPaper name="placeId" label='PlaceId' xs={12} readOnly={readOnly} />
                            <GridPaperSelect name="locationTypeId" label='Location Type' readOnly={readOnly} items={locationType} />
                            <GridPaperSwitch name="isApproved" label='Is Approved' xs={3} readOnly={readOnly} />
                            <GridPaperSwitch name="isActive" label='Is Active' xs={3} readOnly={readOnly} />
                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={2}>
                                    <Paper style={classes.paper}>
                                        {!readOnly &&
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                loading={isSubmitSuccessful || isSubmitting}
                                            >
                                                {item ? "Update" : "Add"}
                                            </LoadingButton>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </FormProvider>
            </Container>
        </>
    )
}

