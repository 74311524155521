export const getPortalMedication = (API, request, callback) => {
    API.get(`medication`, { params: request }).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalAddMedication = (API, request, callback) => {
    API.post(`medication`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalUpdateMedication = (API, request, callback) => {
    API.put(`medication`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const getAllMedicationBrands = (API, request, callback) => {
    API.get(`medication/getAllMedicationBrands`, { params: request }).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    });
}