import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import localStorageAvailable from './useLocalStorage';
import { axiosPrivate } from "../utils/axios";
import { setSession } from "../auth/utils";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const storageAvailable = localStorageAvailable();
    
    useEffect(() => {
        const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers.Authorization) {
                    config.headers.Authorization = `Bearer ${accessToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh().catch((e) => {
                        setSession();
                        window.location.reload();
                    });
                    prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;