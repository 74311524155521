import { Box, Modal, Button } from '@mui/material';

//  eslint-disable react/prop-types
const CustomModal = (prop) => {
    const { open, children, onClose } = prop;

    const handleClose = () => {
        // onClose();
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={{ ...style }}>
                <Button style={{ ...buttonStyle }} variant="outlined" onClick={onClose}>
                    X
                </Button>
                {children}
            </Box>
        </Modal>
    );
}

const buttonStyle = {
    right: '10px',
    position: 'fixed',
    margin: 0,
    minWidth: 0
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflowX: 'scroll',
    height: `${window.innerHeight - 20}px`
};

export default CustomModal;