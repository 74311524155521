import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// components
import LoadingScreen from '../components/loading-screen';
//
import Login from '../pages/auth/LoginPage';
import { useAuthContext } from './useAuthContext';
import ability from '../utils/permission';
import { Roles } from '../layouts/dashboard/nav/config-navigation';
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default function AuthGuard({ children }) {
    const { isAuthenticated, isInitialized, user } = useAuthContext();

    const { pathname } = useLocation();

    const [requestedLocation, setRequestedLocation] = useState(null);

    const renderPage = () => {
        if (user?.role?.name === Roles.Admin) {
            return <Navigate to={PATH_DASHBOARD.dashboard} />;
        }

        return <Navigate to={PATH_DASHBOARD.section} />;
    }

    if (!isInitialized) {
        return <LoadingScreen />;
    }

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <Login />;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        if (ability.can(user?.role?.name, requestedLocation)) {
            return <Navigate to={requestedLocation} />;
        }

        return renderPage();
    }

    if (ability.can(user?.role?.name, pathname)) {
        return <> {children} </>;
    }

    return renderPage();
}
