import { useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import SvgColor from '../../../components/svg-color';
import { StyledIcon } from '../../../components/nav-section/vertical/styles';
import Label from '../../../components/label';
import ConfirmDialog from '../../../components/confirm-dialog';

const icon = (name) => (
    <SvgColor src={`/assets/icons/setting/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
    edit: icon('ic_edit'),
    activate: icon('ic_start'),
    deactivate: icon('ic_stop'),
    profile: icon('ic_user'),
}

const renderStausComponent = (status, text) => (<Label
    variant="soft"
    color={status === true ? 'success' : 'error'}
    sx={{ textTransform: 'capitalize' }}
>
    {text}
</Label>)


const renderStatus = (params) => {
    if (params.row.isActive) {
        return renderStausComponent(params.row.isActive, "Active");
    }
    return renderStausComponent(params.row.isActive, "Deactivated");
}

const renderGender = (params) => {
    if (params.row.genderId === 1) {
        return renderStausComponent(params.row.genderId, "Male");
    }
    if (params.row.genderId ===2 ) {
        return renderStausComponent(params.row.genderId, "Female");
    }
    if (params.row.genderId === 3) {
        return renderStausComponent(params.row.genderId, "Nonbinary");
    }
    return renderStausComponent(params.row.genderId, "Prefer Not To Answer");
}

/* eslint-disable react/prop-types */
const CustomizedButton = ({ text, styledIcon, onClick }) =>
    <Tooltip title={text}>
        <Button onClick={onClick}>
            <StyledIcon>{styledIcon}</StyledIcon>
        </Button>
    </Tooltip>

        /* eslint-disable react/prop-types */
const RenderActionOptions = ({ onDelete, row }) => {
    const [openConfirm, setOpenConfirm] = useState(false);

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    return (
        <>            
            {row.isActive &&
                <>
                    <CustomizedButton text="Delete" onClick={(e) => {
                        e.stopPropagation();
                        handleOpenConfirm();
                    }} styledIcon={ICONS.deactivate} />
                    <ConfirmDialog
                        open={openConfirm}
                        onClose={handleCloseConfirm}
                        title="Delete"
                        content="Are you sure you want to delete this Profile?"
                        action={
                            <Button variant="contained" color="error" onClick={() => {
                                onDelete(row.id);
                                handleCloseConfirm();
                            }}>
                                Yes
                            </Button>
                        }
                    />
                </>
            }
        </>
    )
}

export const getUserProfileListColumn = (props) => [
    { field: 'firstName', headerName: 'First Name', width: 200, sortable: false },
    { field: 'lastName', headerName: 'Last Name', width: 200, sortable: false },
    { field: 'dateOfBirth', headerName: 'Date Of Birth', width: 200, sortable: false },
    { field: 'genderId', headerName: 'Gender', width: 200, sortable: false, renderCell: (params) => renderGender(params) },    
    { field: 'diagnoses', headerName: 'Diagnosis', width: 200, sortable: false },
    { field: 'profileRole', headerName: 'Role', width: 200, sortable: false },
    { field: 'accessLevel', headerName: 'Access Level', width: 200, sortable: false },
    {
        field: 'isActive', headerName: 'Status', width: 150, sortable: false,
        renderCell: (params) => renderStatus(params)
    },    
    {
        field: '#', headerName: 'Actions', width: 200, sortable: false,
        renderCell: (params) => (
            <>
                {params.row.id !== props.currentUser.id ? (params.row.isActive ?
                    // <CustomizedButton text="Delete" onClick={() => { props.onDeactiveProfile(params.row.id); }} styledIcon={ICONS.deactivate} />                    
                    <RenderActionOptions onDelete={props.onDeactiveProfile} row={params.row} />
                     :
                    <CustomizedButton text="Activate" onClick={() => { props.onActiveProfile(params.row.id); }} styledIcon={ICONS.activate} />) : null}
            </>
        )
    }
];