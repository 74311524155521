export const getPortalSymptoms = (API, request, callback) => {
    API.get(`symptom`, { params: request }).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalSymptoms = (API, request, callback) => {
    API.delete(`symptom/${request.id}`).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalAddSymptoms = (API, request, callback) => {
    API.post(`symptom`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalUpdateSymptoms = (API, request, callback) => {
    API.put(`symptom`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalUpdateSymptomStatus = (API, request, callback) => {
    API.patch(`symptom/${request.id}/updateStatus/${request.isActive}`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const getSymptomCategories = (API, request, callback) => {
    API.get(`symptom/categories`, { params: request }).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}