import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { debounce } from "lodash";
// @mui
import { Container, Typography, Stack, Chip, Switch, FormControlLabel, TextField, Button, InputAdornment } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// components
import { useSettingsContext } from '../../components/settings';
import CustomPagination from '../../components/custom-pagination';
import Iconify from '../../components/iconify';
import CustomModal from "../../components/custom-modal";
import FoodView from './FoodView';
import CustomGrid from '../../components/custom-grid';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { getFoodListColumn } from '../../utils/foodColumns';
import { getPortalFoods, onPortalFoodDelete } from '../../APIs/foods';
import { getPortalAminoAcids } from '../../APIs/amino-acid';

// ----------------------------------------------------------------------
import { useAuthContext } from '../../auth/useAuthContext';

export default function FoodList() {
    const { themeStretch } = useSettingsContext();
    const axiosPrivate = useAxiosPrivate();
    const { user } = useAuthContext();

    const [totalFoods, setTotalFoods] = useState(0);
    const [foods, setFoods] = useState([]);
    const [includeOnlyActiveFoods, setIncludeOnlyActiveFoods] = useState(true);
    const [term, setTerm] = useState();
    const [fullWidth] = useState(true);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(20);
    const [openModal, setOpenModal] = useState(false);
    const [selectedFood, setSelectedFood] = useState();
    const [readOnly, setReadOnly] = useState(true);
    const [acids, setAcids] = useState([]);

    const getFoods = (currentPage = 1) => {
        const request = { includeOnlyActiveFoods, term, size, page: currentPage ?? page }
        setPage(request.page);

        getPortalFoods(axiosPrivate, request, (response) => {
            const { data, total } = response;
            setFoods(data);
            setTotalFoods(total);
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getFoods, [includeOnlyActiveFoods, term, size]);

    const getAminoAcids = () => {
        getPortalAminoAcids(axiosPrivate, {}, (response) => {
            if (response.status === 200) {
                setAcids(response.data);
            }
            else {
                setAcids([]);
            }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getAminoAcids, []);

    const handleChange = (event) => {
        setIncludeOnlyActiveFoods(event.target.checked);
    };

    const handleSearch = (event) => {
        setTerm(event.target.value);
    }

    const handlePageChange = (pageIndex) => {
        setPage(pageIndex);
        getFoods(pageIndex);
    }

    const handlePageSizeChange = (pageSize) => {
        setSize(pageSize);
    }

    const onHandleClose = () => {
        setSelectedFood();
        setReadOnly(true);
        setOpenModal(false);
    }

    const handleRowClick = ({ row }) => {
        setSelectedFood(row);
        setOpenModal(true);
        setReadOnly(true);
    }

    const onEdit = (id) => {
        const food = foods.find(c => c.id === id);
        setSelectedFood(food);
        setReadOnly(false);
        setOpenModal(true);
    }

    const onDelete = (id) => {
        onPortalFoodDelete(axiosPrivate, { id }, (response) => {
            if (response.status === 200) {
                getFoods(page);
            }
        });
    }

    const onAdd = () => {
        setReadOnly(false);
        setOpenModal(true);
    }

    const handleUser = () => {
        getFoods(page);
        onHandleClose();
    }

    const debouncedChangeHandler = debounce(handleSearch, 500);

    const columns = getFoodListColumn({ onEdit, onDelete });

    return (
        <>
            <Helmet>
                <title>Foods | flok</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'}>
                <Stack
                    flexGrow={1}
                    direction="column"
                    justifyContent="flex-start"
                    spacing={{ xs: 0.5, sm: 1.5 }}>
                    <Typography variant="h4" component="h4" paragraph>
                        Foods <Chip label={totalFoods} />
                        <Button style={{ marginLeft: '2%', minWidth: 0 }} variant="outlined" onClick={onAdd}>+</Button>
                    </Typography>
                    <Stack
                        flexGrow={1}
                        direction="row"
                        justifyContent="flex-start"
                        spacing={{ xs: 0.5, sm: 1.5 }}>
                        <TextField
                            hiddenLabel
                            placeholder="Search..."
                            size="small"
                            onChange={debouncedChangeHandler}
                            fullWidth={fullWidth}
                            defaultValue={term}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={includeOnlyActiveFoods}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }} />
                            }
                            label="Hide Deleted Foods" />
                    </Stack>
                    <CustomGrid>
                        <DataGrid
                            sx={{
                                '.MuiDataGrid-columnHeaders': {
                                    backgroundColor: 'lightgray'
                                },
                                '.MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '&.MuiDataGrid-root': {
                                    border: 'none'
                                },
                                '.MuiDataGrid-row': {
                                    cursor: 'pointer'
                                },
                                '.MuiDataGrid-footerContainer': {
                                    display: 'none'
                                }
                            }}
                            rows={foods}
                            columns={columns}
                            disableColumnMenu
                            disableColumnFilter
                            disableRowSelectionOnClick
                            hideFooterPagination
                            onRowClick={handleRowClick}
                        />
                    </CustomGrid>
                    <CustomPagination pageSize={size} currentPage={page} total={totalFoods} handleChange={handlePageChange} handlePageSizeChange={handlePageSizeChange} />
                    <CustomModal open={openModal} onClose={onHandleClose}>
                        <FoodView onChangeUser={handleUser} item={selectedFood} onHandleClose={onHandleClose} readOnly={readOnly} acids={acids} />
                    </CustomModal>
                </Stack>
            </Container>
        </>
    );
}