import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Grid, Paper, Typography, Alert } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from '../../components/settings';
import FormProvider, { RHFAutocomplete } from '../../components/hook-form';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { onAddDiagnosis,onUpdateDiagnosis,getAlllDiagnosis } from '../../APIs/diagnosis';
import { GridPaper, GridPaperSelect, GridPaperSwitch } from '../../components/custom-grid-input';

const classes = {
    root: {
        flexGrow: 1
    },
    paper: {
        padding: 5,
        textAlign: "center",
        color: "inherit"
    }
};

/* eslint-disable react/prop-types */
const DiagnosisView = ({ onChangeUser, item, onHandleClose,  readOnly = true }) => {
    const { themeStretch } = useSettingsContext();
    const axiosPrivate = useAxiosPrivate();
    const [sources, setSources] = useState([]);

    const getDataSources = () => {
        getAlllDiagnosis(axiosPrivate, {}, (response) => {
            if (response.status === 200) {
                setSources(response.data);
            }
            else {
                setSources([]);
            }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getDataSources, []);


    const AddDiagnosisSchema = Yup.object().shape({
        id: Yup.number(),
        name: Yup.string().max(45).required('Name is required'),
        subDisorder:Yup.string().max(45).required('subDisorder is required'),
        fullName:Yup.string().max(45).required('FullName is required'),
        abbreviation:Yup.string().max(18).required('Abbreviation is required'),
        hasSubs:Yup.boolean(),
        displayInBubble:Yup.boolean(),
        amminoAcidTracking:Yup.string().max(45).required('AmminoAcidTracking is required'),
        amminoAcidTracking2:Yup.string().max(45).required('AmminoAcidTracking2 is required'),
        amminoAcidTracking3:Yup.string().max(45).required('AmminoAcidTracking3 is required'),
        proteinTracking:Yup.boolean(),
        parentDiagnosisId:Yup.number(),
        isActive: Yup.boolean()                
    });

    const defaultValues = {
        id: item?.id ?? 0,
        name: item?.name ?? '',        
        isActive: item?.isActive ?? true,             
        subDisorder:item?.subDisorder ?? '',
        fullName:item?.fullName ?? '',
        abbreviation:item?.abbreviation ?? '',
        hasSubs:item?.hasSubs ?? true,
        displayInBubble:item?.displayInBubble ?? true,
        amminoAcidTracking:item?.amminoAcidTracking ?? '',
        amminoAcidTracking2:item?.amminoAcidTracking2 ?? '',
        amminoAcidTracking3:item?.amminoAcidTracking3 ?? '',
        proteinTracking:item?.proteinTracking ?? false,
        parentDiagnosisId:item?.parentDiagnosisId??'',        
    };

    const methods = useForm({
        resolver: yupResolver(AddDiagnosisSchema),
        defaultValues
    });

    const {
        setValue,
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = methods;

    const handleSave = (request) => {        
        const newRequest = { ...request }

        if (request.id > 0) {
            updateDiagnosis(newRequest);
        }
        else {
            addDiagnosis(newRequest);
        }
    }

    const addDiagnosis = (request) => {
        onAddDiagnosis(axiosPrivate, request, (response) => {
            if (response.status === 201) {
                onChangeUser(response.data);
            }
            else {
                console.log('error', response);

                reset();

                setError('afterSubmit', {
                    ...response,
                    message: response.data,
                });
            }
        });
    }

    const updateDiagnosis = (request) => {
        onUpdateDiagnosis(axiosPrivate, request, (response) => {
            if (response.status === 200) {
                onChangeUser(response.data);
            }
            else {
                console.log('error', response);

                reset();

                setError('afterSubmit', {
                    ...response,
                    message: response.data,
                });
            }
        });
    }
    
    return (
        <>
            <Helmet>
                <title>View Floks | flok</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'} spacing={{ xs: 0.5, sm: 1.5 }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(handleSave)}>
                    <div style={classes.root}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4" paragraph>
                                    {readOnly ? 'View Floks' : `${item ? 'Update' : 'Add'} Floks`}
                                </Typography>
                                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                            </Grid>
                            <GridPaper name="name" label='Name' xs={12} readOnly={readOnly} />
                            <GridPaper name="fullName" label='Full Name' xs={12} readOnly={readOnly} />
                            <GridPaper name="subDisorder" label='Sub Disorder' xs={12} readOnly={readOnly} />
                            <GridPaper name="abbreviation" label='Abbreviation' xs={12} readOnly={readOnly} />
                            <GridPaper name="amminoAcidTracking" label='AmminoAcid Tracking' xs={12} readOnly={readOnly} />
                            <GridPaper name="amminoAcidTracking2" label='AmminoAcid Tracking-1' xs={12} readOnly={readOnly} />
                            <GridPaper name="amminoAcidTracking3" label='AmminoAcid Tracking-2' xs={12} readOnly={readOnly} />                            
                            <GridPaperSwitch name="proteinTracking" label='Protein Tracking' xs={3} readOnly={readOnly} />
                            <GridPaperSelect name="parentDiagnosisId" label='Parent Floks' readOnly={readOnly} items={sources} />                            
                            <GridPaperSwitch name="displayInBubble" label='Display In Bubble' xs={3} readOnly={readOnly} />
                            <GridPaperSwitch name="hasSubs" label='Has Subs' xs={3} readOnly={readOnly} />                                                        
                            <GridPaperSwitch name="isActive" label='Is Active' xs={3} readOnly={readOnly} />                                                        
                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={2}>
                                    <Paper style={classes.paper}>
                                        {!readOnly &&
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                loading={isSubmitSuccessful || isSubmitting}
                                            >
                                                {item ? "Update" : "Add"}
                                            </LoadingButton>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </FormProvider>
            </Container>
        </>
    )
}

export default DiagnosisView;