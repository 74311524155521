import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const CustomGrid = ({ children }) => {
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }

        window.addEventListener('resize', handleResize);

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    });

    return (
        <div style={{ height: dimensions.height - 300, width: '100%' }}>
            {children}
        </div>
    );
}

CustomGrid.propTypes = {
    children: PropTypes.node,
};

export default CustomGrid;