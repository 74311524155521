import axios from '../utils/axios';
import { setSession } from "../auth/utils";

const useRefreshToken = () => {

    const refresh = async () => {
        const accessToken = localStorage.getItem('accessToken');
        const refToken = localStorage.getItem('refreshToken');

        const response = await axios.post('/auth/refreshtoken', {
            token: accessToken,
            refreshToken: refToken
        });

        const { token, refreshToken } = response.data;

        setSession(token, refreshToken);

        return token;
    }

    return refresh;
};

export default useRefreshToken;