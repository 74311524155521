export const getAppUsers = (API, request, callback) => {
    API.get(`users`, { params: request }).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export const activeAppUser = (API, request, callback) => {
    API.patch(`users/${request.id}/activate/${request.isActive}`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const getAppUserProfiles = (API, request, callback) => {
    API.get(`users/profiles`, { params: request }).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export const deleteAppUserProfile = (API, request, callback) => {
    API.delete(`users/profile/${request.id}/delete`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const activeAppUserProfile = (API, request, callback) => {
    API.patch(`users/profile/${request.id}/active`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const updateAppUserEmail = (API, request, callback) => {
    API.patch(`users/${request.id}/update/email/${request.email}`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const updateAppUserPassword = (API, request, callback) => {
    API.patch(`users/${request.id}/update/password`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}