import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Tooltip, Stack, Typography, Link, Box } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Iconify from '../../components/iconify';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';

// ----------------------------------------------------------------------

export default function Login() {
    const { method } = useAuthContext();

    return (
        <LoginLayout>
            <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
                <Typography variant="h4">Sign in to flok admin</Typography>
            </Stack>

            <AuthLoginForm />

            <Link
                component={RouterLink}
                to={PATH_AUTH.forgotPassword}
                color="inherit"
                variant="subtitle2"
                sx={{
                    mt: 3,
                    mx: 'auto',
                    alignItems: 'center',
                    display: 'inline-flex',
                }}
            >
                <Iconify icon="eva:chevron-left-fill" width={16} />
                Forgot your password?
            </Link>

        </LoginLayout>
    );
}
