export const getPortalLabs = (API, request, callback) => {
    API.get(`lab`, { params: request }).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalLabs = (API, request, callback) => {
    API.delete(`lab/${request.id}`).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalAddLabs = (API, request, callback) => {
    API.post(`lab`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalUpdateLabs = (API, request, callback) => {
    API.put(`lab/${request.id}`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}