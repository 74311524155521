import { useState } from 'react';
import {
    Stack,
    Typography,
    Tooltip,
    Button
} from '@mui/material';
import { StyledIcon } from '../../components/nav-section/vertical/styles';
import SvgColor from '../../components/svg-color';

const icon = (name, color) => (
    <SvgColor src={`/assets/icons/setting/${name}.svg`} sx={{ width: 1, height: 1, color }} />
);

const ICONS = {
    correct: icon('ic_correct', 'green'),
    wrong: icon('ic_wrong', 'red'),
    edit: icon('ic_edit'),
    delete: icon('ic_delete')
}

const renderStatusIcon = (status) => {
    if (status) {
        return <StyledIcon>{ICONS.correct}</StyledIcon>
    }
    return <StyledIcon>{ICONS.wrong}</StyledIcon>
}

const renderParentName = (params) => <Tooltip title={params.row.parentDiagnose?params.row.parentDiagnose.fullName:''}>
    <Stack direction="column">        
            {params.row.parentDiagnose?params.row.parentDiagnose.fullName:''}        
    </Stack>
</Tooltip>

/* eslint-disable react/prop-types */
const CustomizedButton = ({ text, styledIcon, onClick }) =>
    <Tooltip title={text}>
        <Button onClick={onClick}>
            <StyledIcon>{styledIcon}</StyledIcon>
        </Button>
    </Tooltip>

/* eslint-disable react/prop-types */
const RenderActionOptions = ({ onEdit, row }) =>
    <CustomizedButton text="Edit" onClick={(e) => {
        e.stopPropagation();
        onEdit(row.id);
    }} styledIcon={ICONS.edit} />



/* eslint-disable react/prop-types */
export const getDiagnosisListColumns = ({ onEdit }) => [
    {
        field: 'name', headerName: 'Name', width: 300, sortable: false
    },
    {
        field: 'fullName', headerName: 'Full Name', width: 300, sortable: false
    },
    {
        field: 'abbreviation', headerName: 'Abbreviation', width: 300, sortable: false
    },
    {
        field: 'hasSubs', headerName: 'hasSubs', width: 300, sortable: false
    },
    {
        field: 'displayInBubble', headerName: 'DisplayInBubble', width: 300, sortable: false,
        renderCell: (params) => renderStatusIcon(params)
    },
    {
        field: 'parentDiagnoseId', headerName: 'Parent Diagnose', width: 300, sortable: false,
        renderCell: (params) => renderParentName(params)
    },
    {
        field: 'amminoAcidTracking', headerName: 'AmminoAcid Tracking', width: 300, sortable: false
    },
    {
        field: 'amminoAcidTracking2', headerName: 'AmminoAcid Tracking-2', width: 300, sortable: false
    },
    {
        field: 'amminoAcidTracking3', headerName: 'AmminoAcid Tracking-3', width: 300, sortable: false
    },
    {
        field: 'isActive', headerName: 'IsActive', width: 150, sortable: false,
        renderCell: (params) => renderStatusIcon(params.row.isActive)
    },
    {
        field: 'action', headerName: 'Actions', width: 200, sortable: false,
        renderCell: (params) => <RenderActionOptions onEdit={onEdit} row={params.row} />
    }
];