import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Grid, Paper, Typography, Alert } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from '../../components/settings';
import FormProvider, { RHFAutocomplete } from '../../components/hook-form';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { onPortalAddSymptoms, onPortalUpdateSymptoms,getSymptomCategories  } from '../../APIs/symptoms';
import { GridPaper, GridPaperSelect, GridPaperSwitch } from '../../components/custom-grid-input';

const classes = {
    root: {
        flexGrow: 1
    },
    paper: {
        padding: 5,
        textAlign: "center",
        color: "inherit"
    }
};

/* eslint-disable react/prop-types */
export const SymptomsView = ({ onChange,item, onHandleClose, readOnly = true }) => {
    const { themeStretch } = useSettingsContext();
    const axiosPrivate = useAxiosPrivate();
    const [category, setCategory] = useState([]);

    const getDataSources = () => {
        getSymptomCategories(axiosPrivate, {}, (response) => {
            if (response.status === 200) {
                setCategory(response.data);
            }
            else {
                setCategory([]);
            }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getDataSources, []);


    const AddSymptomsSchema = Yup.object().shape({
        id: Yup.number(),
        name: Yup.string().max(60).required('Name is required'),
        isComorbidity: Yup.boolean(),
        isActive: Yup.boolean(),
        keywords: Yup.string().max(250),
        symptomCategoryId: Yup.number().required('Category is required'),        
    });

    const defaultValues = {
        id: item?.id ?? 0,
        name: item?.name ?? '',
        isActive: item?.isActive ?? true,
        isComorbidity: item?.isComorbidity ?? false,
        keywords: item?.keywords ?? '',
        symptomCategoryId: item?.symptomCategoryId ?? '',
    };

    const methods = useForm({
        resolver: yupResolver(AddSymptomsSchema),
        defaultValues
    });

    const {
        setValue,
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = methods;

    const handleSave = (request) => {
        const newRequest = { ...request }

        if (request.id > 0) {
            updateSymptoms(newRequest);
        }
        else {
            addSymptoms(newRequest);
        }
    }

    const addSymptoms = (request) => {
        onPortalAddSymptoms(axiosPrivate, request, (response) => {
            if (response.status === 201) {
                onChange(response.data);
            }
            else {
                console.log('error', response);

                reset();

                setError('afterSubmit', {
                    ...response,
                    message: response.data,
                });
            }
        });
    }

    const updateSymptoms = (request) => {
        onPortalUpdateSymptoms(axiosPrivate, request, (response) => {
            if (response.status === 200) {
                onChange(response.data);
            }
            else {
                console.log('error', response);

                reset();

                setError('afterSubmit', {
                    ...response,
                    message: response.data,
                });
            }
        });
    }

    return (
        <>
            <Helmet>
                <title>View Symptoms | flok</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'} spacing={{ xs: 0.5, sm: 1.5 }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(handleSave)}>
                    <div style={classes.root}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4" paragraph>
                                    {readOnly ? 'View Symptoms' : `${item ? 'Update' : 'Add'} Symptoms`}
                                </Typography>
                                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                            </Grid>
                            <GridPaper name="name" label='Name' xs={12} readOnly={readOnly} />
                            <GridPaper name="keywords" label='keywords' xs={12} readOnly={readOnly} />
                            <GridPaperSelect name="symptomCategoryId" label='Category' readOnly={readOnly} items={category} />
                            <GridPaperSwitch name="isComorbidity" label='Is Comorbidity' xs={3} readOnly={readOnly} />
                            <GridPaperSwitch name="isActive" label='Is Active' xs={3} readOnly={readOnly} />
                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={2}>
                                    <Paper style={classes.paper}>
                                        {!readOnly &&
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                loading={isSubmitSuccessful || isSubmitting}
                                            >
                                                {item ? "Update" : "Add"}
                                            </LoadingButton>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </FormProvider>
            </Container>
        </>
    )
}

