import { useState } from 'react';
import {
    Stack,
    Typography,
    Tooltip,
    Button
} from '@mui/material';
import { StyledIcon } from '../components/nav-section/vertical/styles';
import SvgColor from '../components/svg-color';
import ConfirmDialog from '../components/confirm-dialog';

const icon = (name, color) => (
    <SvgColor src={`/assets/icons/setting/${name}.svg`} sx={{ width: 1, height: 1, color }} />
);

const ICONS = {
    correct: icon('ic_correct', 'green'),
    wrong: icon('ic_wrong', 'red'),
    edit: icon('ic_edit'),
    delete: icon('ic_delete')
}

const renderStatusIcon = (status) => {
    if (status) {
        return <StyledIcon>{ICONS.correct}</StyledIcon>
    }
    return <StyledIcon>{ICONS.wrong}</StyledIcon>
}

const renderName = (params) => <Tooltip title={params.row.name}>
    <Stack direction="column">
        <Typography variant="subtitle2" noWrap>
            {params.row.name}
        </Typography>
        <Typography variant="subtitle4">
            {params.row.brand.name}
        </Typography>
    </Stack>
</Tooltip>

const renderText = (text) => <Tooltip title={text}>
    <Typography variant="subtitle4">
        {text}
    </Typography>
</Tooltip>

/* eslint-disable react/prop-types */
const CustomizedButton = ({ text, styledIcon, onClick }) =>
    <Tooltip title={text}>
        <Button onClick={onClick}>
            <StyledIcon>{styledIcon}</StyledIcon>
        </Button>
    </Tooltip>

/* eslint-disable react/prop-types */
const RenderActionOptions = ({ onEdit, onDelete, row }) => {
    const [openConfirm, setOpenConfirm] = useState(false);

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    return (
        <>
            <CustomizedButton text="Edit" onClick={(e) => {
                e.stopPropagation();
                onEdit(row.id);
            }} styledIcon={ICONS.edit} />
            {row.isActive &&
                <>
                    <CustomizedButton text="Delete" onClick={(e) => {
                        e.stopPropagation();
                        handleOpenConfirm();
                    }} styledIcon={ICONS.delete} />
                    <ConfirmDialog
                        open={openConfirm}
                        onClose={handleCloseConfirm}
                        title="Delete"
                        content="Are you sure you want to delete this food?"
                        action={
                            <Button variant="contained" color="error" onClick={() => {
                                onDelete(row.id);
                                handleCloseConfirm();
                            }}>
                                Yes
                            </Button>
                        }
                    />
                </>
            }
        </>
    )
}

const parseValue = (value, fraction) => {
    if (value) {
        return Number(value.toFixed(fraction));
    }
    return "--";
}

/* eslint-disable react/prop-types */
export const getFoodListColumn = ({ onEdit, onDelete }) => [
    {
        field: 'name', headerName: 'Name', width: 300, sortable: false,
        renderCell: (params) => renderName(params)
    },
    {
        field: 'type', headerName: 'Type', width: 150, sortable: false,
        renderCell: (params) => renderText(params.row.foodType.name)
    },
    {
        field: 'serving', headerName: 'Serving', width: 150, sortable: false,
        renderCell: (params) => renderText(`${parseValue(params.row.servingSize, 1)} ${params.row.servingLabel || params.row.measure.name}`)
    },
    {
        field: 'totalServings', headerName: 'Total Servings', width: 150, sortable: false,
        renderCell: (params) => renderText(parseValue(params.row.totalServings, 1))
    },
    {
        field: 'weight', headerName: 'Weight', width: 150, sortable: false,
        renderCell: (params) => renderText(`${parseValue(params.row.weight_grams, 1)} g`)
    },
    {
        field: 'protein_grams', headerName: 'Proteins', width: 150, sortable: false,
        renderCell: (params) => renderText(`${parseValue(params.row.protein_grams, 2)} g`)
    },
    {
        field: 'calories', headerName: 'Calories', width: 150, sortable: false,
        renderCell: (params) => renderText(parseValue(params.row.calories, 0))
    },
    {
        field: 'isVerified', headerName: 'IsVerified', width: 150, sortable: false,
        renderCell: (params) => renderStatusIcon(params.row.isVerified)
    },
    {
        field: 'isActive', headerName: 'IsActive', width: 150, sortable: false,
        renderCell: (params) => renderStatusIcon(params.row.isActive)
    },
    {
        field: 'isPremium', headerName: 'IsPremium', width: 150, sortable: false,
        renderCell: (params) => renderStatusIcon(params.row.isPremium)
    },
    {
        field: 'isRecipe', headerName: 'IsRecipe', width: 150, sortable: false,
        renderCell: (params) => renderStatusIcon(params.row.isRecipe)
    },
    {
        field: 'action', headerName: 'Actions', width: 200, sortable: false,
        renderCell: (params) => <RenderActionOptions onEdit={onEdit} onDelete={onDelete} row={params.row} />
    }
];