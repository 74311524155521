import PropTypes from 'prop-types';
// @mui
import { Dialog, Button, DialogTitle, DialogActions, DialogContent } from '@mui/material';

// ----------------------------------------------------------------------

ConfirmDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.node,
    action: PropTypes.node,
    content: PropTypes.node,
    onClose: PropTypes.func,
};

export default function ConfirmDialog({ title, content, action, open, onClose, ...other }) {
    return (
        <div style={{ overflowX: 'scroll', height: `${window.inner - 10}px` }}>
            <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
                <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

                {content && <DialogContent dividers={false} sx={{ typography: 'body2' }}> {content} </DialogContent>}

                <DialogActions>
                    {action}

                    <Button variant="outlined" color="inherit" onClick={onClose}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
