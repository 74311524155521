import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_BEFORE_LOGIN, PATH_AFTER_LOGIN } from '../config-global';
//
import {
    Page404,
    PageTwo,
    PageFour,
    LoginPage,
    PageThree,
    ForgotPasswordPage,
    ResetPasswordPage,
    UserList,
    GeneralAppPage,
    CustomerList
} from './elements';
import FoodList from '../pages/food/FoodList';
import LabList from '../pages/labs/LabList';
import AppUserList from '../pages/app-user/AppUserList';
import DiagnosisList from '../pages/diagnosis/DiagnosisList';
import MedicationList from '../pages/medications/medicationList';
import SymptomsList from '../pages/symptoms/SymptomsList';
import ComorbiditiesList from '../pages/comorbidities/ComorbiditiesList';
import LocationsList from '../pages/locations/locationsList';


// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: '/',
            children: [
                { element: <Navigate to={PATH_BEFORE_LOGIN} replace />, index: true }
            ]
        },
        {
            path: '/auth',
            children: [
                { element: <Navigate to={PATH_BEFORE_LOGIN} replace />, index: true },
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <LoginPage />
                        </GuestGuard>
                    ),
                },
                {
                    element: <CompactLayout />,
                    children: [
                        { path: 'forgot-password', element: <ForgotPasswordPage /> },
                        { path: 'reset-password', element: <ResetPasswordPage /> },
                    ],
                },
            ],
        },
        {
            path: '/admin',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                { path: 'users', element: <UserList /> },
                { path: 'dashboard', element: <GeneralAppPage /> },
                { path: 'foods', element: <FoodList /> },
                { path: 'notifications', element: <PageThree /> },
                { path: 'section', element: <PageFour /> },
                { path: 'labs', element: <LabList /> },
                { path: 'appusers', element: <AppUserList /> },
                { path: 'diagnosis', element: <DiagnosisList /> },
                { path: 'medication', element: <MedicationList /> },                
                { path: 'symptoms', element: <SymptomsList /> },
                { path: 'comorbidities', element: <ComorbiditiesList /> },
                { path: 'locations', element: <LocationsList /> }
                
                //  {
                //      path: 'users',
                //      children: [
                //          { element: <Navigate to="/admin/users" replace />, index: true },
                //          { path: 'four', element: <PageFour /> },
                //          { path: 'five', element: <PageFive /> },
                //          { path: 'six', element: <PageSix /> },
                //      ],
                //  },
            ],
        },
        {
            element: <CompactLayout />,
            children: [{ path: '404', element: <Page404 /> }],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}
