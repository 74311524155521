export const getPortalFoods = (API, request, callback) => {
    API.get(`foods`, { params: request }).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalFoodDelete = (API, request, callback) => {
    API.delete(`foods/${request.id}`).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalAddFood = (API, request, callback) => {
    API.post(`foods`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalUpdateFood = (API, request, callback) => {
    API.put(`foods/${request.id}`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}