export const searchComorbidities = (API, request, callback) => {
    API.get(`comorbidities`, { params: request }).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export const onAddComorbidity = (API, request, callback) => {
    API.post(`comorbidities`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onUpdateComorbidity = (API, request, callback) => {
    API.put(`comorbidities/${request.id}`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalUpdateComorbidityStatus = (API, request, callback) => {
    API.patch(`comorbidities/${request.id}/updateStatus/${request.isActive}`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}