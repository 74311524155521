import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Grid, Paper, Typography, Alert } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from '../../components/settings';
import FormProvider, { RHFAutocomplete } from '../../components/hook-form';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { onPortalAddMedication,onPortalUpdateMedication,getAllMedicationBrands } from '../../APIs/medication';
import { GridPaper, GridPaperSelect, GridPaperSwitch } from '../../components/custom-grid-input';

const classes = {
    root: {
        flexGrow: 1
    },
    paper: {
        padding: 5,
        textAlign: "center",
        color: "inherit"
    }
};

/* eslint-disable react/prop-types */
const MedicationView = ({ onChange, item, onHandleClose, readOnly = true }) => {
    const { themeStretch } = useSettingsContext();
    const axiosPrivate = useAxiosPrivate();

    const [brands, setBrands] = useState([]);

    const [showZero] = useState(true);
    
    const AddLabSchema = Yup.object().shape({
        id: Yup.number(),
        name: Yup.string().max(50).required('Name is required'),        
        medicationBrandId: Yup.string().required('Brand is required'),        
        isActive: Yup.boolean()                
    });

    const defaultValues = {
        id: item?.id ?? 0,
        name: item?.name ?? '',        
        isActive: item?.isActive ?? true,
        medicationBrandId:item?.medicationBrandId ?? '',
    };

    const methods = useForm({
        resolver: yupResolver(AddLabSchema),
        defaultValues
    });

    const {
        setValue,
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = methods;

    const MedicationBrands = () =>
    {
        getAllMedicationBrands(axiosPrivate, {},(response) =>{            
            const arr = [];
            response.forEach(element => {
                arr.push({
                    id:element.id,
                    name:element.name
                })
            });            
            setBrands(arr);            
        });

    }

     // eslint-disable-next-line react-hooks/exhaustive-deps
     useEffect(MedicationBrands, []);
    
    const handleSave = (request) => {
        const newRequest = { ...request }

        if (request.id > 0) {
            updateMedication(newRequest);
        }
        else {
            addMedication(newRequest);
        }
    }

    const addMedication = (request) => {
        onPortalAddMedication(axiosPrivate, request, (response) => {
            if (response.status === 201) {
                onChange(response.data);
            }
            else {
                console.log('error', response);

                reset();

                setError('afterSubmit', {
                    ...response,
                    message: response.data,
                });
            }
        });
    }

    const updateMedication = (request) => {
        onPortalUpdateMedication(axiosPrivate, request, (response) => {
            if (response.status === 200) {
                onChange(response.data);
            }
            else {
                console.log('error', response);

                reset();

                setError('afterSubmit', {
                    ...response,
                    message: response.data,
                });
            }
        });
    }
    
    return (
        <>
            <Helmet>
                <title>View Medication | flok</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'} spacing={{ xs: 0.5, sm: 1.5 }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(handleSave)}>
                    <div style={classes.root}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4" paragraph>
                                    {readOnly ? 'View Medication' : `${item ? 'Update' : 'Add'} Medication`}
                                </Typography>
                                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                            </Grid>
                            <GridPaper name="name" label='Name' xs={12} readOnly={readOnly} />                            
                            <GridPaperSwitch name="isActive" label='Is Active' xs={3} readOnly={readOnly} />                                          
                            <GridPaperSelect name="medicationBrandId" label='Brand' xs={10} readOnly={readOnly} items={brands} />
                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={2}>
                                    <Paper style={classes.paper}>
                                        {!readOnly &&
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                loading={isSubmitSuccessful || isSubmitting}
                                            >
                                                {item ? "Update" : "Add"}
                                            </LoadingButton>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </FormProvider>
            </Container>
        </>
    )
}

export default MedicationView;